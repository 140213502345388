// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Joel",
  middleName: "Outeiro",
  lastName: "Pérez",
  message: " Desenvolvedor full-stack praticando cousas en Dart / Flutter ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/joelop3",
    },
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/joelop3/",
    },
    {
      image: "fa-twitter",
      url: "https://www.twitter.com/joelop_gz/",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "Sobre min",
  imageLink: require("../editable-stuff/joelouteiro.png"),
  imageSize: 375,
  message:
    " O meu nome é Joel Outeiro Pérez, son Desenvolvedor de software web e apps. Saquei o titulo de Técnico Superior no CIFP A Carballeira durante os anos 2019-2021. Son usuario de Linux dende que teño memoria. Actualmente estou a aprender Dart / Flutter e apaixoname criar aplicacións multiplataforma que axuden ás pequenas empresas a ser máis produtivas e que se poidan centrar mellor no seu traballo. ",
  resume: require("../editable-stuff/resume.pdf")
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Proxectos recentes",
  gitHubUsername: "joelop3", 
  reposLength: 4,
  specificRepos: [],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    { 
      img: require("../editable-stuff/joelouteiro.png"), 
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/joelouteiro.png"), 
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Competencias",
  hardSkills: [
    { name: "HTML/CSS", value: 90 },
    { name: "PHP", value: 90 },
    { name: "JavaScript", value: 75 },
    { name: "SQL", value: 75 },
    { name: "C#", value: 85 },
    { name: "Python", value: 70 },
    { name: "Java", value: 85 },
    { name: "Bash", value: 95 },
  ],
  softSkills: [
    { name: "Flexibilidade", value: 75 },
    { name: "Autoaprendizaxe", value: 90 },
    { name: "Criatividade", value: 75 },
    { name: "Resiliencia", value: 70 },
    { name: "Resolución de problemas", value: 85 },
    { name: "Organizacion", value: 80 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Qué mais sobre min?",
  message:
    "Actualmente traballando como desenvolvedor multiplataforma para min mesmo baixo o nome de TecnoCousas, contacto en",
  email: "info@tecnocousas.com"
};

const experiences = {
  companylogo: require('../assets/img/origami.png'),
  show: true,
  heading: "Experiencias laborais",
  data: [
    {
      role: 'Voluntario informático para aplicacións Apps / Web Apps',
      companylogo: require('../assets/img/agal.png'),
      companyname: "Asociaçom Galega da Língua (AGAL)",
      date: 'Febreiro 2019 – Presente',
    },
    {
      companylogo: require('../assets/img/origami.png'),
      companyname: "Origami Soluciones S.L",
      role: 'Desenvolvedor de aplicacións Web e Apps',
      date: 'Marzo 2021 – Decembro 2021',
    },
    {
      companylogo: require('../assets/img/tecnocousas.png'),
      companyname: "TecnoCousas",
      role: 'Desenvolvedor multiplataforma',
      date: 'Setembro 2021 – Presente',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences };
